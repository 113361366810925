<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Category"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Category" label-for="mc-category">
                    <select
                      class="form-control"
                      v-model="model.category_indicator_parent_id"
                      @change="changeParent"
                    >
                      <option value="">Pilih Kategori Indikator</option>
                      <option
                        v-for="(i, index) in categories"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Sub Category"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Sub Category" label-for="mc-category">
                    <select
                      class="form-control"
                      v-model="model.category_indicator_id"
                    >
                      <option value="">Pilih Sub Kategori Indikator</option>
                      <option
                        v-for="(i, index) in categoriesSub"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nama (ID)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama (ID)" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Masukkan Nama"
                      v-model="model.name"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nama (EN)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama (EN)" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Masukkan Nama"
                      v-model="model.data_en.name"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Keterangan Singkat (ID)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Keterangan Singkat  (ID)" label-for="mc-short_desc_id">
                    <b-form-input
                      id="mc-short_desc_id"
                      placeholder="Masukkan Keterangan Singkat"
                      v-model="model.short_desc_id"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Keterangan Singkat (EN)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Keterangan Singkat  (EN)" label-for="mc-short_desc_en">
                    <b-form-input
                      id="mc-short_desc_en"
                      placeholder="Masukkan Keterangan Singkat"
                      v-model="model.short_desc_en"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Informasi (ID)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="textarea-auto-height">Informasi (ID)</label>
                  <quill-editor
                    v-model="model.information"
                    :options="editorOption"
                  >
                  </quill-editor>
                  <!-- <b-form-textarea
                    id="textarea-auto-height"
                    placeholder="Masukkan Informasi"
                    rows="3"
                    v-model="model.information"
                    max-rows="8"
                    class="mb-1 mb-xl-0"
                  /> -->

                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Informasi (EN)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="textarea-auto-height">Informasi (EN)</label>
                  <quill-editor
                    v-model="model.data_en.information"
                    :options="editorOption"
                  >
                  </quill-editor>

                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </b-col>

              <!-- <b-col md="12">
                <ValidationProvider
                  name="Description"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="textarea-auto-height">Description</label>
                  <quill-editor v-model="model.description" :options="editorOption">
                  </quill-editor>

                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </b-col> -->

              <b-col md="6">
                <ValidationProvider
                  name="Data Period"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Data Period" label-for="mc-data-period">
                    <select class="form-control" v-model="model.data_periode">
                      <option value="">Pilih Periode</option>
                      <option
                        v-for="(i, index) in data_periods"
                        :key="index"
                        :value="i.value"
                      >
                        {{ i.label }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- <b-col md="6">
                <ValidationProvider
                  name="Data Unit"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Data Unit" label-for="mc-data-location">
                    <select class="form-control" v-model="model.data_location">
                      <option value="">Pilih Unit</option>
                      <option
                        v-for="(i, index) in data_locations"
                        :key="index"
                        :value="i.value"
                      >
                        {{ i.label }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->

              <b-col md="6">
                <ValidationProvider
                  name="Sumber Data"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Sumber Data" label-for="mc-data-source">
                    <b-form-input
                      id="mc-data-source"
                      placeholder="Masukkan Sumber Data"
                      v-model="model.data_source"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <!--<b-col md="4">
                <ValidationProvider
                  name="Satuan"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Satuan" label-for="mc-data-source">
                    <b-form-input
                      id="mc-data-source"
                      placeholder="Masukkan Satuan"
                      v-model="model.satuan"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>-->

              <b-col md="6">
                <ValidationProvider
                  name="Data Field"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Data Field" label-for="mc-data-field">
                    <b-form-input
                      id="mc-data-field"
                      placeholder="Masukkan Data Field"
                      v-model="model.data_field"
                      @blur="checkingDataInput"
                    />
                    <p v-if="err_data_field == true" class="fs-6 text-danger">
                      Format tidak di dukung, Gunakan _ (Underscore) untuk
                      menyambung kata
                    </p>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Last Update"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Last Update" label-for="mc-date">
                    <date-picker
                      :firstDayOfWeek="1"
                      placeholder="Masukkan Tanggal"
                      v-model="model.last_updated_at"
                      name="date"
                      :lang="lang"
                      :format="formats"
                      value-type="YYYY-MM-DD"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Default "
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Map Default View" label-for="mc-date">
                    <date-picker
                      :firstDayOfWeek="1"
                      placeholder="Masukkan Tanggal"
                      v-model="model.default_view_date"
                      name="date"
                      :lang="lang"
                      :format="formats"
                      value-type="YYYY-MM-DD"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Use Distric Income"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Use Distric Income"
                    label-for="mc-use-district-income"
                  >
                    <b-form-radio-group
                      v-model="model.use_district_income"
                      :options="useDistrictList"
                      class="mt-1"
                      value-field="value"
                      text-field="label"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Status" label-for="mc-status">
                    <b-form-radio-group
                      v-model="model.status"
                      :options="statusList"
                      class="mt-1"
                      value-field="value"
                      text-field="label"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Interval"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="">
                    <div class="demo-inline-spacing mt-0">
                      <span class="mr-1">Interval : </span>
                      <b-form-checkbox
                        v-for="option in peiodeOptions"
                        :key="option.value"
                        v-model="model.intervals"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </b-form-checkbox>
                    </div>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'indicators' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    quillEditor,
    DatePicker,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            [{ font: [] }, { size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            // [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [{ header: "1" }, { header: "2" }],
            [
              { list: "ordered" },
              { list: "bullet" },
              // { indent: "-1" },
              // { indent: "+1" },
            ],
            // ["direction", { align: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
        theme: "snow",
      },

      name: "UsersForm",
      errors: {},
      peiodeOptions: [
        {
          value: "year",
          name: "Year",
        },
        {
          value: "month",
          name: "Month",
        },
        {
          value: "day",
          name: "Day",
        },
        {
          value: "quartal",
          name: "Quartal",
        },
      ],
      showDismissibleAlert: false,
      config: {
        api: "/indicator",
        category: "/category_indicator?orderby=id&sort=desc&parent_id=0",
        category_sub: "/category_indicator?orderby=id&sort=desc&parent_id=",
        redirect: "indicators",
      },
      model: {
        data_periode: "",
        data_location: "-",
        category_indicator_id: "",
        category_indicator_parent_id: "",
        description: "-",
        data_en: {
          name: null,
          information: null,
        },
        intervals: [],
      },
      confirmPass: null,
      err_data_field: false,
      categories: [],
      categoriesSub: [],
      data_periods: [
        {
          label: "Year",
          value: "year",
        },
        {
          label: "Month",
          value: "month",
        },
        {
          label: "Quartal",
          value: "quartal",
        },
        {
          label: "Day",
          value: "day",
        },
      ],
      data_locations: [
        {
          label: "Province",
          value: "province",
        },
        {
          label: "District",
          value: "district",
        },
      ],
      statusList: [
        {
          label: "Data Only",
          value: "summary_only",
        },
        {
          label: "Dashboard and Data",
          value: "chart_and_summary",
        },
        {
          label: "Tidak Aktif",
          value: "inactive",
        },
      ],
      useDistrictList: [
        {
          label: "Aktif",
          value: true,
        },
        {
          label: "Tidak Aktif",
          value: false,
        },
      ],
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
      messages: "",
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getCategories();
  },
  methods: {
    changeParent() {
      let id = this.model.category_indicator_parent_id;
      this.model.category_indicator_id = "";
      this.getCategoriesSub(id);
    },
    checkingDataInput() {
      if (
        this.model.data_field.indexOf("-") > -1 ||
        this.model.data_field.indexOf(" ") > -1 ||
        this.model.data_field.indexOf(",") > -1 ||
        this.model.data_field.indexOf(".") > -1
      ) {
        this.err_data_field = true;
        this.model.data_field = null;
      } else {
        this.err_data_field = false;
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    getCategories() {
      let _ = this;
      axios.get(_.config.category).then((response) => {
        let _ = this;
        _.categories = response.data.data.rows;
      });
    },
    getCategoriesSub(id) {
      let _ = this;
      axios.get(_.config.category_sub + id).then((response) => {
        let _ = this;
        _.categoriesSub = response.data.data.rows;
      });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
            _.model.category_indicator_parent_id =
              _.model.category_indicator.parent.id;
            this.getCategoriesSub(_.model.category_indicator_parent_id);
          });
      }
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        let data = {
          name: _.model.name,
          short_desc_en: _.model.short_desc_en,
          short_desc_id: _.model.short_desc_id,
          category_indicator_id: _.model.category_indicator_id,
          data_periode: _.model.data_periode,
          data_location: _.model.data_location,
          satuan: _.model.satuan,
          status: _.model.status,
          information: _.model.information,
          data_source: _.model.data_source,
          data_en: {
            name: _.model.data_en.name,
            information: _.model.data_en.information,
          },
          data_field: _.model.data_field,
          description: _.model.description,
          use_district_income: _.model.use_district_income,
          last_updated_at: _.model.last_updated_at,
          default_view_date: _.model.default_view_date,
          intervals: _.model.intervals,
        };
        axios
          .post(_.config.api, data)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        let data = {
          name: _.model.name,
          short_desc_en: _.model.short_desc_en,
          short_desc_id: _.model.short_desc_id,
          category_indicator_id: _.model.category_indicator_id,
          data_periode: _.model.data_periode,
          data_location: _.model.data_location,
          satuan: _.model.satuan,
          status: _.model.status,
          information: _.model.information,
          data_source: _.model.data_source,
          data_en: {
            name: _.model.data_en.name,
            information: _.model.data_en.information,
          },
          data_field: _.model.data_field,
          description: _.model.description,
          use_district_income: _.model.use_district_income,
          last_updated_at: _.model.last_updated_at,
          default_view_date: _.model.default_view_date,
          intervals: _.model.intervals,
        };
        axios
          .put(_.config.api + "/" + _.$route.params.id, data)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Indicator Success",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
};
</script>
