var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category","label-for":"mc-category"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category_indicator_parent_id),expression:"model.category_indicator_parent_id"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category_indicator_parent_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.changeParent]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Kategori Indikator")]),_vm._l((_vm.categories),function(i,index){return _c('option',{key:index,domProps:{"value":i.id}},[_vm._v(" "+_vm._s(i.name)+" ")])})],2),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Sub Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sub Category","label-for":"mc-category"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category_indicator_id),expression:"model.category_indicator_id"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category_indicator_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Sub Kategori Indikator")]),_vm._l((_vm.categoriesSub),function(i,index){return _c('option',{key:index,domProps:{"value":i.id}},[_vm._v(" "+_vm._s(i.name)+" ")])})],2),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama (ID)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama (ID)","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Nama"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama (EN)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama (EN)","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Nama"},model:{value:(_vm.model.data_en.name),callback:function ($$v) {_vm.$set(_vm.model.data_en, "name", $$v)},expression:"model.data_en.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Keterangan Singkat (ID)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Keterangan Singkat  (ID)","label-for":"mc-short_desc_id"}},[_c('b-form-input',{attrs:{"id":"mc-short_desc_id","placeholder":"Masukkan Keterangan Singkat"},model:{value:(_vm.model.short_desc_id),callback:function ($$v) {_vm.$set(_vm.model, "short_desc_id", $$v)},expression:"model.short_desc_id"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Keterangan Singkat (EN)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Keterangan Singkat  (EN)","label-for":"mc-short_desc_en"}},[_c('b-form-input',{attrs:{"id":"mc-short_desc_en","placeholder":"Masukkan Keterangan Singkat"},model:{value:(_vm.model.short_desc_en),callback:function ($$v) {_vm.$set(_vm.model, "short_desc_en", $$v)},expression:"model.short_desc_en"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Informasi (ID)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"textarea-auto-height"}},[_vm._v("Informasi (ID)")]),_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.model.information),callback:function ($$v) {_vm.$set(_vm.model, "information", $$v)},expression:"model.information"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Informasi (EN)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"textarea-auto-height"}},[_vm._v("Informasi (EN)")]),_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.model.data_en.information),callback:function ($$v) {_vm.$set(_vm.model.data_en, "information", $$v)},expression:"model.data_en.information"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Data Period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data Period","label-for":"mc-data-period"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.data_periode),expression:"model.data_periode"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "data_periode", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Periode")]),_vm._l((_vm.data_periods),function(i,index){return _c('option',{key:index,domProps:{"value":i.value}},[_vm._v(" "+_vm._s(i.label)+" ")])})],2),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Sumber Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sumber Data","label-for":"mc-data-source"}},[_c('b-form-input',{attrs:{"id":"mc-data-source","placeholder":"Masukkan Sumber Data"},model:{value:(_vm.model.data_source),callback:function ($$v) {_vm.$set(_vm.model, "data_source", $$v)},expression:"model.data_source"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Data Field","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data Field","label-for":"mc-data-field"}},[_c('b-form-input',{attrs:{"id":"mc-data-field","placeholder":"Masukkan Data Field"},on:{"blur":_vm.checkingDataInput},model:{value:(_vm.model.data_field),callback:function ($$v) {_vm.$set(_vm.model, "data_field", $$v)},expression:"model.data_field"}}),(_vm.err_data_field == true)?_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" Format tidak di dukung, Gunakan _ (Underscore) untuk menyambung kata ")]):_vm._e(),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Last Update","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Last Update","label-for":"mc-date"}},[_c('date-picker',{attrs:{"firstDayOfWeek":1,"placeholder":"Masukkan Tanggal","name":"date","lang":_vm.lang,"format":_vm.formats,"value-type":"YYYY-MM-DD"},model:{value:(_vm.model.last_updated_at),callback:function ($$v) {_vm.$set(_vm.model, "last_updated_at", $$v)},expression:"model.last_updated_at"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Default ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Map Default View","label-for":"mc-date"}},[_c('date-picker',{attrs:{"firstDayOfWeek":1,"placeholder":"Masukkan Tanggal","name":"date","lang":_vm.lang,"format":_vm.formats,"value-type":"YYYY-MM-DD"},model:{value:(_vm.model.default_view_date),callback:function ($$v) {_vm.$set(_vm.model, "default_view_date", $$v)},expression:"model.default_view_date"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Use Distric Income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Use Distric Income","label-for":"mc-use-district-income"}},[_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":_vm.useDistrictList,"value-field":"value","text-field":"label"},model:{value:(_vm.model.use_district_income),callback:function ($$v) {_vm.$set(_vm.model, "use_district_income", $$v)},expression:"model.use_district_income"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"mc-status"}},[_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":_vm.statusList,"value-field":"value","text-field":"label"},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Interval","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":""}},[_c('div',{staticClass:"demo-inline-spacing mt-0"},[_c('span',{staticClass:"mr-1"},[_vm._v("Interval : ")]),_vm._l((_vm.peiodeOptions),function(option){return _c('b-form-checkbox',{key:option.value,attrs:{"value":option.value},model:{value:(_vm.model.intervals),callback:function ($$v) {_vm.$set(_vm.model, "intervals", $$v)},expression:"model.intervals"}},[_vm._v(" "+_vm._s(option.name)+" ")])})],2),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1)],1),_c('b-card-footer',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'indicators' }}},[_vm._v(" Batal ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }